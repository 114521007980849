import storage from 'services/utils/storage';
import moment from 'moment';

export const initialTourSteps = {
  // hasAnalticsTourViewed: false,
  // hasCompanyTourViewed: false,
  // hasLeadTourViewed: false,
  // hasMessagingTourViewed: false,
  // hasChatTourViewed: false,
  // hasCustomerTourViewed: false,
  // hasReportTourViewed: false,
  // hasOnBoardingTourViewed: false
};

export const initialTourState = {
  tourModeEnabled: false,
  pages: { ...initialTourSteps }
};
export const tourStepHandler = variant => {
  let tour = JSON.parse(storage.getItem('Tour'));

  if (TourEnum[variant]) {
    storage.setItem('Tour', {
      tourModeEnabled: true,
      pages: {
        ...tour.pages,
        [TourEnum[variant]]: true
      }
    });
  }
};

export const TourEnum = {
  voice: 'hasVoiceTourViewed',
  chatbot: 'hasChatbotTourViewed',
  analytics: 'hasAnalticsTourViewed',
  company: 'hasCompanyTourViewed',
  lead: 'hasLeadTourViewed',
  messaging: 'hasMessagingTourViewed',
  chat: 'hasChatTourViewed',
  customer: 'hasCustomerTourViewed',
  report: 'hasReportTourViewed',
  onBoarding: 'hasOnBoardingTourViewed'
};

export const hasStepExpired = date => {
  let stepDate = moment(date);
  let now = moment();
  let duration = moment.duration(stepDate.diff(now));
  let inDays = parseInt(duration.asDays());
  if (inDays >= 0 || inDays === -0) {
    return false;
  }
  return true;
};

export const getInitialStep = (tour, stepData = []) => {
  let initStep = 0;
  if (
    stepData.length &&
    tour
    // && stepData.length - 1 > Object.keys(TourEnum).length - 1
  ) {
    let { pages } = tour;
    let index = stepData.findIndex(step => !pages[TourEnum[step.key]]);
    initStep = index && index >= 0 ? index : 0;
  }

  return initStep;
};

export const onTourModalSetTourState = (isEnabled = true) => {
  let tour = JSON.parse(storage.getItem('Tour'));
  let initializeTour = {
    ...initialTourState,
    tourModeEnabled: isEnabled
  };
  if (tour) {
    initializeTour['pages'] = {
      ...initializeTour['pages'],
      ...tour.pages
    };
  }
  storage.setItem('Tour', initializeTour);
};

export const onCompleteSetTourState = (isEnabled = true, steps = []) => {
  let tour = JSON.parse(storage.getItem('Tour'));
  let isDefaultSteps = {};
  steps.forEach(step => {
    if (!step.isNew && TourEnum[step.key]) {
      isDefaultSteps[`${TourEnum[step.key]}`] = false;
    }
  });
  let initializeTour = {
    pages: { ...tour.pages, ...isDefaultSteps },
    tourModeEnabled: isEnabled
  };
  storage.setItem('Tour', initializeTour);
};
