import { createSlice } from '@reduxjs/toolkit';

export const nodeMenuSlice = createSlice({
  name: 'nodeMenu',
  initialState: { IsVisible: false },
  reducers: {
    changeNodeMenuVisibility: state => {
      state.IsVisible = !state.IsVisible;
    }
  }
});

export const { changeNodeMenuVisibility } = nodeMenuSlice.actions;

// Export the reducer to be used in the store
export default nodeMenuSlice.reducer;
