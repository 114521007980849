import React from 'react';

const ViewCompany = React.lazy(() =>
  import('features/company/pvt-components/view')
);

const Chat = React.lazy(() => import('pages/portal/conversation'));
const ViewLead = React.lazy(() => import('features/lead/pvt-components/view'));
const CreateChatBot = React.lazy(() =>
  import('features/chatbot/pvt-components/CreateChatBot')
);
const ViewReport = React.lazy(() => import('pages/portal/report/view'));
const ViewProvision = React.lazy(() => import('pages/portal/provision/view'));
const WidgetSettings = React.lazy(() =>
  import('features/settings/pvt-components/settings-detail')
);
const ChatBotDetail = React.lazy(() =>
  import('features/chatbot/pvt-components/ChatBotDetail')
);
const ChatBotEdit = React.lazy(() =>
  import('pages/portal/chatBot/chatBotEdit')
);

const nestedRoutes = [
  {
    path: '/portal/chatbot/:id',
    component: props => <ChatBotEdit {...props} />,
    name: 'chatbot',
    exact: true
  },
  {
    path: '/portal/companies/:id',
    component: props => <ViewCompany {...props} />,
    exact: true
  },
  {
    path: '/portal/chatbot/create/:type',
    component: props => <CreateChatBot {...props} />,
    name: 'chatbot',
    secondKey: 'edit',
    exact: false
  },
  {
    path: '/portal/chatbot/:id/:type?',
    component: props => <ChatBotDetail {...props} />,
    name: 'chatbot',
    exact: false
  },
  {
    path: '/portal/conversations/:id',
    component: props => <Chat {...props} />,
    exact: true
  },
  {
    path: '/portal/leads/:id',
    component: props => <ViewLead {...props} />,
    exact: true
  },
  {
    path: '/portal/report/:id',
    component: props => <ViewReport {...props} />,
    exact: true
  },
  {
    path: '/portal/onboarding/:id',
    component: props => <ViewProvision {...props} />,
    exact: true
  },
  {
    path: '/portal/settings/:companyId/:type?',
    name: 'settings-comany-details',
    title: 'Company settings',
    component: props => <WidgetSettings {...props} />,
    exact: false
  }
];

export default nestedRoutes;
