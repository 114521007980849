export const fr_tour = {
  tourWelcome:
    'Bonjour <user>, bienvenue sur le nouveau tableau de bord de chat.',
  tourWelcomeText:
    'Jetons un rapide tour du nouveau tableau de bord de chat pour vous montrer et de vous familiariser avec les fonctionnalités et les changements que vous pouvez faire maintenant.',
  featureTourWelcome: 'Bonjour <user>, bienvenue sur le nouveau portail.',
  featureTourWelcomeText:
    'Faisons un tour rapide des nouvelles fonctionnalités et familiarisons-nous avec les fonctionnalités et les modifications que vous pouvez apporter maintenant.',
  featureTourDisclaimer:
    "Avis de non-responsabilité : en fonction des rôles et des autorisations, peu d'utilisateurs peuvent ne pas voir certaines fonctionnalités.",
  whatsNew: 'Quoi de neuf',
  weHaveAnUpdate: 'Nous avons une mise à jour !',
  tourSubHeading:
    'Nous avons ajouté les éléments manquants suivants dans le nouveau portail:',
  tourAnalytics:
    'Bienvenue dans le nouveau tableau de bord analytique. Plus est à venir!',
  tourMessaging:
    "Vous pouvez maintenant utiliser le nouveau tableau d'affichage amélioré dans le portail.",
  tourCompanies:
    "L'onglet entreprise vous permet d'apporter des modifications à votre compte.",
  tourChats: 'Chats entrants traités pour les six derniers mois.',
  tourLeads: 'Responsable du chat entrant au cours des six derniers mois.',
  tourChatbot:
    "Créez et personnalisez un chatbot alimenté par l'IA pour votre site web, permettant des conversations automatisées et améliorant l'engagement des utilisateurs.",
  tourVoice:
    "Accédez à des analyses vocales détaillées et à des enregistrements d'appels pour suivre votre performance efficacement.",
  tourReports:
    'Vous pouvez désormais créer de nouveaux rapports et consulter les rapports existants dans le nouveau portail.',
  tourOnboarding:
    'Vous pouvez créer un nouveau compte dans le nouveau portail.',
  tourHelp:
    '<p><b>SUPPORT BOT</b> - Le support bot est là pour répondre aux questions et envoyer un ticket à notre équipe interne qui vous répondra.</p><p><b>VISITE DE LANCEMENT</b> - Faites une visite rapide et familiarisez-vous avec la nouvelle mise en page.</p>',
  tourSwitch: 'Vous pouvez basculer entre les systèmes nouveaux et existants.',
  tourEnd: "C'est la fin de la tournée",
  tourEnd2:
    "Nous espérons que vous avez apprécié la visite et nous nous réjouissons d'apporter plus de fonctionnalités au nouveau tableau de bord de chat bientôt. Si vous avez des questions, veuillez joindre à nous. Nous sommes toujours là pour vous aider.",
  btn1: 'Peut-être plus tard',
  btn2: 'Tour initial',
  btn3: 'Précédent',
  btn4: "J'ai compris",
  btn5: 'Suivant',
  btn6: 'Proche',
  btn7: 'Passer le tour',
  btn8: 'Fait',
  btn9: 'Fonctionnalités récemment ajoutées',
  feature: {
    exportFeature: 'The button will allow you to export grid data.'
  },
  tourSettings:
    "<p><b>Réglages</b> - Obtenez vos options d'intégration de widget ici."
};

export default fr_tour;
