import ApiServices from 'api/apexchat.api.services';

// const baseUrl = 'http://localhost:3457/Services/ApexChatService.svc/';

export class BlazeoApi {
  async getCompany(id) {
    return await ApiServices.WCF_get(
      `simplecompany/${id}/id,companyKey,companyName,active,StandardProfileAID,phoneProfileAID`
    );
  }

  async getAgentScript(companyId, lang = 'eng') {
    return await ApiServices.WCF_get(
      `scripts/multiLanguage/?companyId=${companyId}&threeLetterISOLanguageName=${lang}`
    );
  }

  async getProfile(profileId) {
    return await ApiServices.WCF_get(`profiles/${profileId}`);
  }

  async getProfileTexts(profileId) {
    return await ApiServices.WCF_get(`profiles/profiletexts/${profileId}`);
  }

  async getChatGreetingAnswerId(profileID) {
    const result = await this.getProfile(profileID);
    const profile = result.data.data;

    if (profile.preChatEnabled) {
      if (profile.preChatMessages[0].messageType === 1) {
        return profile.preChatMessages[0].agentScriptAnswerId;
      } else {
        return 0;
      }
    } else {
      if (profile.profileTexts[0].greetingType === 1) {
        return profile.profileTexts[0].agentScriptAnswerIdForGreeting;
      } else {
        return 0;
      }
    }
  }

  async getScriptBotFlows(companyId) {
    return await ApiServices.WCF_get(`companies/${companyId}/scriptbotflow/`);
  }

  async getScriptBotFlow(companyId, chatBotId) {
    return await ApiServices.WCF_get(
      `companies/${companyId}/scriptbotflow/${chatBotId}`
    );
  }

  async updateScriptBotFlow(companyId, chatBotId, flowJson) {
    return await ApiServices.WCF_postJson(
      `companies/${companyId}/scriptbotflow/${chatBotId}`,
      flowJson
    );
  }

  async deleteScriptBotFlow(companyId, chatBotId) {
    return await ApiServices.WCF_delete(
      `companies/${companyId}/scriptbotflow/${chatBotId}`
    );
  }
}

export const BlazeoDataPromise = async companyId => {
  const blazeoApi = new BlazeoApi();
  const company = (await blazeoApi.getCompany(companyId)).data.data;
  let agentScript = (await blazeoApi.getAgentScript(company.id, 'enb')).data
    ?.data;
  if (!agentScript?.id) {
    agentScript = (await blazeoApi.getAgentScript(company.id, 'eng')).data
      ?.data;
  }
  const chatGreetingAnswerId = await blazeoApi.getChatGreetingAnswerId(
    company.standardProfileAId
  );

  return {
    company: company,
    agentScript: agentScript,
    chatGreetingAnswerId: chatGreetingAnswerId
  };
};
