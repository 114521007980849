import React from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from '@xyflow/react';

import './styles.scss';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeNodeMenuVisibility } from '../node_menu/nodeMenu-slice';
import NodeMenu from '../node_menu/NodeMenu';

export default function CustomEdge(props) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd
  } = props;
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition
  });

  const [showPopup, setVisible] = useState(false);
  const IsNodeMenuVisibile = useSelector(state => state.nodeMenu.IsVisible);
  const dispatch = useDispatch();

  const onEdgeClick = (evt, id, props) => {
    evt.stopPropagation();
    eventChangeVisibility();
  };

  const eventChangeVisibility = () => {
    if (showPopup === false && IsNodeMenuVisibile === false) {
      setVisible(true);
      dispatch(changeNodeMenuVisibility());
    } else if (showPopup === true) {
      setVisible(false);
      dispatch(changeNodeMenuVisibility());
    }
  };

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all'
          }}
          className="nodrag nopan"
        >
          <button
            className={'edgebutton'}
            onClick={event => onEdgeClick(event, id, props)}
          >
            +
          </button>
          <NodeMenu
            visible={showPopup}
            eventChangeVisibility={eventChangeVisibility}
            source={props.source}
            target={props.target}
            sourceX={labelX}
            sourceY={labelY}
          />
        </div>
      </EdgeLabelRenderer>
    </>
  );
}
