export const fr_tour = {
  tourWelcome:
    'Hola <user>, bienvenido al nuevo tablero de instrumentos de chat.',
  tourWelcomeText:
    'Echemos un rápido recorrido por el nuevo tablero de instrumentos de chat de mostrarle los alrededores y familiarizarse con la funcionalidad y cambios que puede hacer ahora.',
  featureTourWelcome: 'Hola <user>, bienvenida al nuevo portal.',
  featureTourWelcomeText:
    'Hagamos un recorrido rápido por las nuevas funciones y familiarícese con la funcionalidad y los cambios que puede realizar ahora.',
  featureTourDisclaimer:
    'Descargo de responsabilidad: según las funciones y los permisos, es posible que algunos usuarios no vean algunas funciones.',
  whatsNew: 'Qué hay de nuevo',
  weHaveAnUpdate: 'Tenemos una actualización!',
  tourSubHeading:
    'Hemos agregado los siguientes elementos faltantes en el nuevo portal:',
  tourAnalytics: 'Bienvenido al nuevo panel de análisis. Pronto habrá más!',
  tourMessaging:
    'Ahora puede usar el tablero de mensajes nuevo y mejorado dentro del portal.',
  tourCompanies:
    'La pestaña de empresa le permite realizar cambios en su cuenta.',
  tourChats: 'Chats entrantes manejados durante los últimos seis meses.',
  tourLeads: 'Líder de chat entrante durante los últimos seis meses',
  tourChatbot:
    'Cree y personalice un chatbot con inteligencia artificial para su sitio web, permitiendo conversaciones automatizadas y mejorando la interacción con los usuarios.',
  tourVoice:
    'Acceda a análisis detallados de voz y grabaciones de llamadas para rastrear su rendimiento de manera efectiva.',
  tourReports:
    'Ahora puede crear nuevos informes y revisar los informes existentes dentro del nuevo portal.',
  tourOnboarding: 'Puede incorporar una nueva cuenta dentro del nuevo portal.',
  tourHelp:
    '<p><b>BOT DE SOPORTE</b> - el bot de soporte está aquí para responder preguntas y enviar un ticket a nuestro equipo interno, que se comunicará contigo.</p><p><b>VISITA DE LANZAMIENTO</b> - haz un recorrido rápido y familiarízate con el nuevo diseño.</p>',
  tourSwitch: 'Puede cambiar entre los sistemas nuevos y existentes.',
  tourEnd: 'Ese es el final de la gira.',
  tourEnd2:
    'Esperamos que hayan disfrutado de la gira y mira adelante a traer más características para el nuevo tablero de instrumentos de chat pronto. Si tiene alguna pregunta, por favor llegar a nosotros. Siempre estamos aquí para ayudar.',
  btn1: 'Quizas mas tarde',
  btn2: 'Empezar recorrido',
  btn3: 'Anterior',
  btn4: 'Entendido',
  btn5: 'próximo',
  btn6: 'Cerca',
  btn7: 'Omitir recorrido',
  btn8: 'Hecho',
  btn9: 'Características añadidas recientemente',
  feature: {
    exportFeature: 'The button will allow you to export grid data.'
  },
  tourSettings:
    '<p><b>Ajustes</b> - Obtenga sus opciones de integración de widgets aquí.'
};

export default fr_tour;
