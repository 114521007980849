import React from "react";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import { Icon } from "@material-ui/core";

const MaskedInputField = ({
  label,
  value,
  onChange,
  mask,
  maskChar = '_',
  placeholder,
  endIcon: EndIcon,
  onIconClick,
  helperText,
  error,
  ...props
}) => {
  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      maskChar={maskChar}
    >
      {inputProps => (
        <TextField
          {...inputProps}
          {...props}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          error={error}
          helperText={error && helperText}
          fullWidth
          InputProps={{
            inputProps: {
              style: { padding: '14px', paddingRight: '0px' }
            },
            endAdornment: EndIcon && (
              <InputAdornment position="end">
                <EndIcon />
              </InputAdornment>
            )
          }}
        />
      )}
    </InputMask>
  );
};

export default MaskedInputField;
