import { initialEdges } from '../edges';
import { initialNodes } from '../nodes';
import { createSlice } from '@reduxjs/toolkit';
import { applyEdgeChanges, applyNodeChanges, addEdge } from '@xyflow/react';
import { getLayoutedElements } from '../nodes/layoutModifier';
import { updateOptionText } from '../menu/menu-slice';
import { getInitialNodes } from '../scriptbot_flow/scriptbotflow-slice';

const nodes = initialNodes;
const edges = initialEdges;

const flowState = {
  nodes: nodes,
  edges: edges,
  reload: false
};

const hasOptions = questionType => {
  return questionType === 1 || questionType === 2 || questionType === 7;
};

export const flowSlice = createSlice({
  name: 'flow',
  initialState: flowState,
  reducers: {
    initializeFlow: state => {
      state.nodes = initialNodes;
      state.edges = initialEdges;
      state.reload = false;
    },
    addNode: (state, action) => {
      if (action.payload.data.question.questionType === null)
        action.payload.data.question.questionType = 0;

      state.nodes.push(action.payload);
    },
    addNewEdge: (state, action) => {
      state.edges.push(action.payload);
      const newNodes = [];
      state.nodes.forEach(node => {
        if (node.id === action.payload.source) {
          if (hasOptions(node.data.question.questionType)) {
            node.data.question.agentScriptOptions.push();
          } else {
            node.data.question.nextQuestionId = action.payload.target;
          }
        }
        newNodes.push(node);
      });
      state.nodes = newNodes;
    },
    addNewEdges: (state, action) => {
      action.payload.forEach(edge => {
        state.edges.push(edge);
      });
    },
    onNodesChangeRedux: (state, action) => {
      state.nodes = applyNodeChanges(action.payload, state.nodes);
    },
    onEdgesChangeRedux: (state, action) => {
      state.edges = applyEdgeChanges(action.payload, state.edges);
    },
    onConnectRedux: (state, action) => {
      state.edges = addEdge(action.payload, state.edges);
    },
    setFlowState: (state, action) => {
      state.nodes = action.payload.nodes;
      state.edges = action.payload.edges;

      state.reload = true;
    },
    setNodes: (state, action) => {
      state.nodes = action.payload;
    },
    setEdges: (state, action) => {
      state.edges = action.payload;
    },
    updateNode: (state, action) => {
      const edgesToRemove = [];
      const edgesToAdd = [];
      state.nodes = state.nodes.map(node => {
        if (node.id === action.payload.id) {
          // Node that is being Saved
          if (hasOptions(action.payload.data.question.questionType)) {
            if (node.data.question.agentScriptOptions?.length > 0) {
              node.data.question.agentScriptOptions.forEach(option => {
                if (option.nextQuestionId !== 0) {
                  edgesToRemove.push({
                    source: action.payload.id,
                    target: option.nextQuestionId.toString()
                  });
                }
              });
            }
            if (action.payload.data.question.agentScriptOptions?.length > 0) {
              action.payload.data.question.agentScriptOptions.forEach(
                option => {
                  if (option.nextQuestionId !== 0) {
                    const newEdge = {
                      id: `${action.payload.id}->${option.nextQuestionId}`,
                      type: 'question-edge',
                      source: action.payload.id,
                      target: option.nextQuestionId.toString()
                    };
                    edgesToAdd.push(newEdge);
                  }
                }
              );
            }
          } else {
            const newNextQuestionId =
              action.payload.data.question.nextQuestionId;
            const oldNextQuestionId = node.data.question.nextQuestionId;

            if (newNextQuestionId !== 0) {
              if (newNextQuestionId !== oldNextQuestionId) {
                const newEdge = {
                  id: `${action.payload.id}->${action.payload.data.question.nextQuestionId}`,
                  type: 'question-edge',
                  source: action.payload.id,
                  target: action.payload.data.question.nextQuestionId.toString()
                };
                edgesToAdd.push(newEdge);

                edgesToRemove.push({
                  source: action.payload.id,
                  target: oldNextQuestionId
                });
              }
            }
          }
          return action.payload;
        } else {
          return node;
        }
      });

      const newEdges = [];
      state.edges.forEach(edge => {
        let shouldRemove = false;
        edgesToRemove.forEach(edgeToRemove => {
          if (
            edge.source === edgeToRemove.source &&
            edge.target === edgeToRemove.target
          ) {
            shouldRemove = true;
          }
        });
        if (!shouldRemove) {
          newEdges.push(edge);
        }
      });

      edgesToAdd.forEach(edge => newEdges.push(edge));

      state.edges = newEdges;
    },
    deleteNode: (state, action) => {
      const newNodes = [];
      const newEdges = [];
      let sourceNodeId = null;

      state.edges.forEach(edge => {
        if (
          edge.source !== action.payload.id &&
          edge.target !== action.payload.id
        ) {
          newEdges.push(edge);
        } else {
          sourceNodeId = edge.source;
        }
      });
      state.edges = newEdges;

      state.nodes.forEach(node => {
        if (node.id === sourceNodeId) {
          node.data.question.nextQuestionId = 0;
        }
        if (node.id !== action.payload.id) newNodes.push(node);
      });
      state.nodes = newNodes;
    },
    deleteNodes: (state, action) => {
      action.payload.forEach(deletingNode => {
        const newNodes = [];
        const newEdges = [];
        let sourceNodeId = null;

        state.edges.forEach(edge => {
          if (
            edge.source !== deletingNode.id &&
            edge.target !== deletingNode.id
          ) {
            newEdges.push(edge);
          } else {
            sourceNodeId = edge.source;
          }
        });
        state.edges = newEdges;

        state.nodes.forEach(node => {
          if (node.id === sourceNodeId) {
            node.data.question.nextQuestionId = 0;
          }
          if (node.id !== deletingNode.id) newNodes.push(node);
        });
        state.nodes = newNodes;
      });
    },
    deleteEdge: (state, action) => {
      const newEdges = [];

      state.edges.forEach(edge => {
        if (
          edge.source !== action.payload.id &&
          edge.target !== action.payload.target
        )
          newEdges.push(edge);
      });
      state.edges = newEdges;
    },
    onReorganize: (state, action) => {
      const flowState = getLayoutedElements(
        state.nodes,
        state.edges,
        action.payload
      );
      state.nodes = flowState.nodes;
      state.edges = flowState.edges;

      state.reload = true;
    },
    resetReload: state => {
      state.reload = false;
    },

    setReload: (state, action) => {
      state.reload = action.payload;
    },

    updateNextQuestionId: (state, action) => {
      const newNodes = [];

      state.nodes.forEach(node => {
        if (node.id === action.payload.source) {
          node.data.question.nextQuestionId = action.payload.newId;
          newNodes.push(node);
        } else {
          newNodes.push(node);
        }
      });

      state.nodes = newNodes;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getInitialNodes.fulfilled, (state, action) => {
        const initialEdge = {
          id: `start->${action.payload.firstQuestionId}`,
          type: 'question-edge',
          source: 'start',
          target: action.payload.firstQuestionId.toString()
        };

        const nodes = action.payload.nodes.concat(initialNodes);
        const edges = action.payload.edges.concat(initialEdge);

        const flowState = getLayoutedElements(nodes, edges, 'TB');

        state.nodes = flowState.nodes;
        state.edges = flowState.edges;

        state.reload = true;
      })
      .addCase(updateOptionText, (state, action) => {
        const newEdges = [];
        state.edges.forEach(edge => {
          if (
            edge.source === action.payload.source &&
            edge.target === action.payload.target
          ) {
            edge.label = action.payload.text;
          }
          newEdges.push(edge);
        });
        state.edges = newEdges;
      });
  }
});

export const {
  initializeFlow,
  addNode,
  addNewEdge,
  addNewEdges,
  onNodesChangeRedux,
  onEdgesChangeRedux,
  onConnectRedux,
  onReorganize,
  deleteNode,
  deleteNodes,
  deleteEdge,
  updateNode,
  setFlowState,
  resetReload,
  setReload,
  updateNextQuestionId
} = flowSlice.actions;
export default flowSlice.reducer;
